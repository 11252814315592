import { useEffect, useRef, useState, useMemo, useCallback } from "react";
import Switch from "./components/Switch";
import { FiDollarSign } from "react-icons/fi";
import { FiServer } from "react-icons/fi";
import status from "../../assets/images/logo/status.svg";
import image1 from "../../assets/images/cards/Rectangle 29852.png";
import image2 from "../../assets/images/cards/Rectangle 29852(2).png";
import useGetOrder from "../../hooks/queries/useGetOrder";
import useGetAllOrders from "../../hooks/queries/orders/useGetAllOrders";
import useGetMyOrders from "../../hooks/queries/orders/useGetMyOrders";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { AiOutlineSend } from "react-icons/ai";
import api from "../../api/axios";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../../assets/images/logo/grey_compress.png";
import { BiUser } from "react-icons/bi";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { useSpinner } from "../../context/Spinner";
import { BiImageAdd } from "react-icons/bi";
import { saveAs } from "file-saver";
import { FaExternalLinkAlt } from "react-icons/fa";
import JSZip from "jszip";
import Payment from "../payment/Payment";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import { debounce } from "lodash"; // Import debounce from lodash or another library
import io from "socket.io-client";
import imageCompression from "browser-image-compression";
const SWITCHED_TIME = 1679759879;
const STATUS_CODES = {
  pending: 0,
  reviewed: 1,
  paid: 2,
  processing: 3,
  completed: 4,
  cancelled: 5,
};

const OrdersList = ({ admin, userId, props }) => {
  const [clientSecret, setClientSecret] = useState("");
  const paypal = useRef();
  const [orderStatus, setOrderStatus] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [order, setOrder] = useState(null);
  // const [admin, setAdmin] = useState(false)
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState(null);
  const [amount, setAmount] = useState();
  const [title, setTitle] = useState("");
  const [orders, setOrders] = useState([]);
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(true);
  const [cs, setCs] = useState(false);
  const [csi, setCsi] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const socket = useRef(null);
  const [isTyping, setIsTyping] = useState(false);
  const [typingRole, setTypingRole] = useState(null);
  const [currentlyTyping, setCurrentlyTyping] = useState(false);
  const [inprogressOrdersCount, setInprogressOrdersCount] = useState(null);
  const [completedOrdersCount, setcompletedOrdersCount] = useState(null);


  const navigate = useNavigate();

 
  const chatContainerRef = useRef(null);
  const dummyRef = useRef(null);
  const inputRef = useRef(null);

  // const orders = useGetAllOrders();
  // const myOrders = useGetMyOrders(userId);
  const divRef = useRef(null);
  const divRef2 = useRef(null);
  const imageFileExtentions = [".jpg", ".jpeg", ".png", ".gif"];
  const [imageFile, setImageFile] = useState();

  useEffect(() => {
    socket.current = io("https://prod-api.3dspot.io");

    socket.current.on("adminTyping", (data) => {
      if (data.orderId === selectedOrder) {
        // console.log("Admin typing event handled", data);
        setIsTyping(data.isTyping);
        setTypingRole("admin");
      }
    });

    socket.current.on("userTyping", (data) => {
      if (data.orderId === selectedOrder) {
        // console.log("User typing event handled", data);
        setIsTyping(data.isTyping);
        setTypingRole("user");
      }
    });

    return () => {
      if (socket.current) {
        socket.current.disconnect();
      }
    };
  }, [selectedOrder]);
// Auto-scroll effect: Only scroll if shouldScrollToBottom is true.
useEffect(() => {
  if (chatContainerRef.current && shouldScrollToBottom) {
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }
}, [order?.comments, shouldScrollToBottom]);

  const handleStartTyping = () => {
    socket.current.emit("startTyping", {
      orderId: selectedOrder,
      userId,
      isAdmin: admin,
    });
  };
  // Helper function to render a chat message
const renderChatMessage = (message) => {
  // Regular expression to detect URLs anywhere in the message
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const trimmed = message.trim();
  const foundUrls = trimmed.match(urlRegex) || [];

  // If the entire message is exactly one URL
  if (foundUrls.length === 1 && trimmed === foundUrls[0]) {
    // If it's an image URL (based on common image file extensions)
    if (/\.(jpg|jpeg|png|gif)$/i.test(trimmed)) {
      return (
        <a href={trimmed} target="_blank" rel="noopener noreferrer">
          <img
            className="object-contain w-[150px] h-[150px]"
            src={trimmed}
            alt="Image Preview"
          />
        </a>
      );
    } else {
      return (
        <a
          href={trimmed}
          target="_blank"
          rel="noopener noreferrer"
          className="underline text-blue-400"
        >
          {trimmed}
        </a>
      );
    }
  }

  // If the message is a mix of text and URL(s)
  // Split the message into parts using the URL regex
  const parts = message.split(urlRegex);
  return parts.map((part, index) => {
    // If this part matches a URL
    if (urlRegex.test(part)) {
      if (/\.(jpg|jpeg|png|gif)$/i.test(part)) {
        return (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            <img
              className="object-contain w-[150px] h-[150px]"
              src={part}
              alt="Image Preview"
            />
          </a>
        );
      } else {
        return (
          <a
            key={index}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            className="underline text-blue-400"
          >
            {part}
          </a>
        );
      }
    }
    // Otherwise render the text normally
    return <span key={index}>{part}</span>;
  });
};

  

  const handleStopTyping = useCallback(
    debounce(() => {
      socket.current.emit("stopTyping", {
        orderId: selectedOrder,
        userId,
        isAdmin: admin,
      });
    }, 2000),
    [selectedOrder, userId, admin]
  );

  // Call this function when the user starts typing
  const handleTyping = (isTyping, isAdmin) => {
    if (isTyping) {
      if (!currentlyTyping) {
        socket.current.emit("startTyping", {
          orderId: selectedOrder,
          userId,
          isAdmin,
        });
        setCurrentlyTyping(true);
      }
      debouncedStopTyping(isAdmin); // This will reset the debounce timer every time the user types
    } else {
      socket.current.emit("stopTyping", {
        orderId: selectedOrder,
        userId,
        isAdmin,
      });
      setCurrentlyTyping(false);
    }
  };

  const debouncedStopTyping = debounce((isAdmin) => {
    // console.log(`debouncedStopTyping called: isAdmin=${isAdmin}`);

    handleTyping(false, isAdmin);
  }, 2000); // Adjust the debounce time as needed

  const handleFileUpload = async (e) => {
    // 1) Get only image files
    const files = Array.from(e.target.files).filter(file =>
      file.type.match(/image.*/)
    );
    if (!files.length) return;
  
    // 2) Set compression options (adjust as needed)
    const compressionOptions = {
      maxSizeMB: 1,            // compress to around 1 MB max
      maxWidthOrHeight: 1920,  // limit dimensions
      useWebWorker: true,
    };
  
    // 3) Compress images and create local preview URLs for an optimistic update
    const localPreviews = await Promise.all(
      files.map(async (file) => {
        try {
          // Compress the image
          const compressedFile = await imageCompression(file, compressionOptions);
          // Create a local preview URL
          const previewUrl = URL.createObjectURL(compressedFile);
          return { file: compressedFile, previewUrl };
        } catch (error) {
          console.error("Error compressing image:", error);
          // Fallback: use original file if compression fails
          const previewUrl = URL.createObjectURL(file);
          return { file, previewUrl };
        }
      })
    );
  
    // 4) Immediately update the order state so images appear instantly
    setOrder((prevOrder) => {
      if (!prevOrder) return prevOrder;
      return {
        ...prevOrder,
        images: [...(prevOrder.images || []), ...localPreviews.map(lp => lp.previewUrl)],
      };
    });
  
    // 5) Show a loading indicator
    spinner.setLoadingState(true);
  
    try {
      // 6) Upload all images in parallel
      const uploadPromises = localPreviews.map(async ({ file, previewUrl }) => {
        const formData = new FormData();
        formData.append("image", file, file.name);
        formData.append("orderId", order._id);
        formData.append("comment", "");
        formData.append("title", order.title);
  
        const response = await api.post("/orders/comment", formData);
        
        // Optional: If your backend returns a final URL, you can replace the local preview:
        // const finalImageUrl = response.data.finalImageUrl;
        // if (finalImageUrl) {
        //   setOrder(prevOrder => {
        //     if (!prevOrder) return prevOrder;
        //     return {
        //       ...prevOrder,
        //       images: prevOrder.images.map(img => img === previewUrl ? finalImageUrl : img)
        //     };
        //   });
        // }
      });
  
      await Promise.all(uploadPromises);
  
      // 7) Fetch the updated order details (ensure the final URLs are loaded)
      await fetchOrder(order._id);
    } catch (error) {
      console.error("Error uploading images", error);
      toast.error("Image upload failed.");
    } finally {
      // 8) Stop the loading indicator
      spinner.setLoadingState(false);
    }
  
    // 9) Trigger any UI update if needed
    setCsi((prev) => !prev);
  };

  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }, [cs]);
  useEffect(() => {
    if (divRef2.current) {
      divRef2.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }, [csi]);
  const spinner = useSpinner();

  const currency = "USD";
  const updateStatus = async (status, orderId) => {
    try {
      const response = await api.put("/orders/", {
        orderId,
        status,
      });
      console.log(response.data);
      fetchOrders();
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  // const getImage = (createdAt, image) => {
  //   // jb creaetdAt switch wale time se phle hoga to pixel wala url nhi to nya url hoga
  //   const _createdAt = new Date(createdAt);
  //   if (_createdAt.getTime > SWITCHED_TIME) {
  //     return `https://project3dspot.s3.ap-south-1.amazonaws.com/${image}`;
  //   } else {
  //     return `https://rk-images.s3.amazonaws.com/${image}`;
  //   }
  // };
  const fetchOrders = async () => {
    spinner.setLoadingState(true);
    const { data } = await api.get("/orders");
    setOrders(data);
    spinner.setLoadingState(false);
  };
  const fetchOrder = useCallback(
    debounce(async (id) => {
      setLoading(true);
      if (id) {
        const response = await api.get("/orders/details/" + id);
        setOrder(response.data);
        setCs(!cs);
      }
      setLoading(false);
    }, 1000), // Adjust the debounce delay as needed
    [cs]
  );
  const deleteOrder = async (id) => {
    setLoading(true);
    if (id) {
      const response = await api.delete("/orders/delete/" + id);
      setOrder(response.data);
    }
    toast.success("Order deleted successfully");
    fetchOrders();
    setLoading(false);
  };
  const cancelOrder = async (id) => {
    setLoading(true);
    if (id) {
      const response = await api.delete("/orders/cancel/" + id);
      setOrder(response.data);
    }
    toast.success("Order canceled successfully");
    fetchOrders();
    setLoading(false);
  };
  const handleReview = async () => {
    const response = await api.post("/orders/review", {
      orderId: order._id,
      price: price,
    });
    fetchOrder(order._id);
    fetchOrders();
    setPrice();
    // console.log(response.data);
  };
  const handleReview_update_price = async () => {
    const response = await api.post("/orders/update_price", {
      orderId: order._id,
      price: price,
    });
    fetchOrder(order._id);
    fetchOrders();
    setPrice();
    // console.log(response.data);
  };
  const handleComment = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post("/orders/comment", {
        orderId: order._id,
        comment: comment,
        title: order.title,
      });
      // console.log(response);
    } catch (error) {
      console.log(error);
    }

    fetchOrder(order._id);
    setComment("");
    // setCs(!cs);
    setCsi(!csi);

    // console.log(response.data);
  };

  const handleFileDownload = async () => {
    const files = [];

    for (const [index, image] of order.images.entries()) {
      const fileExtension = "jpeg";
      const fileName = `${order._id}_${index}.${fileExtension}`;

      try {
        const response = await fetch(
          `https://project3dspot.s3.ap-south-1.amazonaws.com/${image}`
        );
        if (!response.ok) {
          throw new Error("Failed to download file");
        }
        const blob = await response.blob();
        files.push({ blob, fileName });
      } catch (error) {
        console.error(`Failed to download file: ${fileName}`);
      }
    }

    if (files.length === 0) {
      console.error("No files to download");
      return;
    }

    if (files.length === 1) {
      saveAs(files[0].blob, files[0].fileName);
    } else {
      const zip = new JSZip();
      files.forEach(({ blob, fileName }) => {
        zip.file(fileName, blob);
      });
      zip
        .generateAsync({ type: "blob" })
        .then((blob) => {
          saveAs(blob, "images.zip");
        })
        .catch((error) => {
          console.error("Failed to generate zip file:", error);
        });
    }
  };

  const handleFileDownloadChat = async (image) => {
    const fileExtension = "jpeg";
    const fileName = `${image.split("/").pop()}.${fileExtension}`;

    try {
      const response = await fetch(image);
      const blob = await response.blob();
      saveAs(blob, fileName);
    } catch (error) {
      console.error(`Failed to download file: ${fileName}`);
    }
  };

  useEffect(() => {
    const userEmail = order?.contact?.emailId; // Make sure this is how you get the user's email
    if (userEmail) {
      const fetchInprogressOrdersCount = async () => {
        try {
          const { data } = await api.get(
            `/orders/getInprogressOrdersCount/${userEmail}`
          );
          // Or using your api utility if it's defined elsewhere
          // const { data } = await api.get(`/api/orders/getInprogressOrdersCount/${userEmail}`);
          console.log(
            "Fetched inprogress orders count:",
            data.inprogressOrdersCount
          );
          setInprogressOrdersCount(data.inprogressOrdersCount); // Update state with fetched count
          setcompletedOrdersCount(data.completedOrdersCount); // Update state with fetched count
        } catch (error) {
          console.error("Failed to fetch inprogress orders count:", error);
        }
      };

      fetchInprogressOrdersCount();
    }
  }, [order?.contact?.emailId]);


  useEffect(() => {
    fetchOrder(selectedOrder);
  }, [selectedOrder]);
  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchOrder(selectedOrder);
    }, 1000 * 5); // in milliseconds
    return () => clearInterval(intervalId);
  });

  // Search Orders
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchUsers, setSearchUsers] = useState([]);
  const HandleSearchUsers = async (searchtext) => {
    try {
      // console.log("Searching for:", searchtext);

      // Make sure searchText is properly encoded for the URL
      const encodedSearchText = encodeURIComponent(searchtext);

      const response = await api.get(
        `/orders/search?keyword=${encodedSearchText}`
      );
      console.log("Response from API:", response.data);

      setSearchUsers(response.data);
    } catch (error) {
      console.error("Error in HandleSearchUsers:", error);
    }
  };

  const removeQueryParams = () => {
    const param = searchParams.get("q");
    if (param) {
      searchParams.delete("q");
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    HandleSearchUsers(" ");
  }, [searchParams.length == 0]);

  useEffect(() => {
    HandleSearchUsers("");
    // console.log(searchParams.length, "search param length")
  }, []);

  useEffect(() => {
    removeQueryParams();
  }, [searchParams.length == 0]);

  useEffect(() => {
    const param = searchParams.get("q");
    // console.log("Param to searc in api", param)
    HandleSearchUsers(param);
  }, [searchParams]);

  useEffect(() => {
    if (shouldScrollToBottom) {
      divRef.current?.scrollTo(0, divRef.current?.scrollHeight);
    }
  }, [order?.comments, shouldScrollToBottom]);

  const handleScroll = () => {
    if (!chatContainerRef.current) return;
    const { scrollTop, clientHeight, scrollHeight } = chatContainerRef.current;
    // If the user is near the bottom, enable auto-scroll.
    const isAtBottom = scrollTop + clientHeight >= scrollHeight - 10;
    setShouldScrollToBottom(isAtBottom);
  };
  const planMapping = {
    1: "Character Models",
    2: "Concept Art/Action Figures",
    3: "Vehicle Models",
  };
  const subscriptionPlanString = planMapping[order?.contact?.subscriptionPlan];

  return (
    <div className="drawer drawer-end w-full min-h-screen">
      <input id="my-drawer-4" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content p-8 lg:pr-0 w-full h-full">
        <section className="text-white text-left">
          <div className="flex flex-col lg:flex-row justify-between items-center w-full">
            {admin ? (
              <p className="text-5xl font-bold mb-6"></p>
            ) : (
              <p className="text-5xl font-bold mb-6">My Orders</p>
            )}
            <form className="form-control" onSubmit={HandleSearchUsers}>
              <div className="input-group mb-3">
                <input
                  type="text"
                  placeholder="Search for orders"
                  className="input input-bordered bg-[#1B1C2D] w-60"
                  onChange={(e) => setSearchParams({ q: e.target.value })}
                />
                <button className="btn btn-square" type="button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                </button>
              </div>
            </form>
          </div>
          <FloatingWhatsApp
            phoneNumber="19177643908"
            accountName="3D Spot"
            allowEsc
            allowClickAway
            notification
            avatar={logo}
            notificationSound
            style={{ color: "black" }}
          />
          <Switch
            orders={searchParams.get("q") ? searchUsers : orders}
            setSelectedOrder={setSelectedOrder}
            isAdmin={admin}
            deleteOrder={deleteOrder}
            setAmount={setAmount}
            setClientSecret={setClientSecret}
          />
        </section>
      </div>

      <div className="drawer-side">
        <label htmlFor="my-drawer-4" className="drawer-overlay"></label>

        <ul className="menu example fixed top-0 right-0 flex-1  scrollbar-hide p-4 mt-16 pb-16 w-11/12 text-base-content bg-[#06011A] lg:w-1/3 sidebar-gradient">
          {/* <!-- Sidebar content here --> */}
          <div className="overflow-y-scroll w-full scrollbar-hide">
            <button
              className="absolute top-4 right-3 fixed bg-gradient-to-br from-transparent to-[#2d1582] text-white rounded-full p-2 hover:from-[#fd004e] hover:to-[#2d1582]"
              onClick={() => {
                document.getElementById("my-drawer-4").checked = false;
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>

            <div className="flex flex-row items-start text-base font-semibold text-[#FBFAFC] pb-4">
              <div className="pr-2">Title : </div>
              <div className="text-base text-white opacity-60 pl-2">
                {order?.title}
              </div>
            </div>
            <div className="flex flex-col items-start text-base font-semibold text-[#FBFAFC] pb-4">
              <div>Tracking Id:</div>
              <div className="text-base text-white opacity-60">
                {order?._id}
              </div>
            </div>

            {/* <div className="flex items-start text-base font-semibold text-[#FBFAFC] pb-4">
            {order?._id}
          </div> */}

            <div className="pb-6 flex justify-between">
              <div className="flex flex-col">
                <div className="flex items-start pb-1 text-[#FBFAFC] text-opacity-60">
                  Price
                </div>
                <div className="flex items-start ">
                  <FiDollarSign className="text-[#FBFAFC] text-opacity-60" />
                  <div className="flex items-start text-white relative bottom-[3px]">
                  {order?.price ? ((order.price / 2) * 1.045).toFixed(2) : "Not Quoted"}

                  </div>
                </div>
              </div>
              {/* <div className="flex flex-col">
                  <div className="flex items-start pb-2">
                    <FiServer className="text-[#FBFAFC] text-opacity-60" />
                  </div>
                  <div className="flex items-start text-[#FBFAFC] text-opacity-60">
                    File Size
                  </div>
                  <div className="flex items-start text-white">25 Mb</div>
                </div> */}
              <div>
                <div className="flex items-start pb-1 text-[#FBFAFC] text-opacity-60">
                  Status
                </div>
                <div className="flex items-start ">
                  <img src={status} />
                  <div className="flex items-start text-[#00FDEE] pl-2 relative bottom-[3px]">
                    {order?.status}
                  </div>
                </div>
              </div>
              <div></div>
            </div>
            <div className="flex flex-col items-start text-base font-semibold text-[#FBFAFC] pb-4">
              {admin && (
                <button
                  onClick={handleFileDownload}
                  className="secondary-button w-full md:w-64 my-1"
                >
                  Download Images
                </button>
              )}
            </div>

            <div className="flex items-start text-base font-semibold text-[#FBFAFC] pb-4">
              Uploaded Images
            </div>

            <div className="grid grid-cols-2 gap-2 pb-2">
              {order?.images?.map((image) => {
                return (
                  <div>
                    <a
                      href={`https://project3dspot.s3.ap-south-1.amazonaws.com/${image}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        alt="order-img"
                        className="rounded-lg w-[250px] h-[150px] object-cover"
                        src={`https://project3dspot.s3.ap-south-1.amazonaws.com/${image}`}
                      />
                    </a>
                  </div>
                );
              })}
            </div>

            <div className="flex items-start text-base font-semibold text-[#FBFAFC]">
              Notes
            </div>
            <div className="flex items-start text-[#FBFAFC] text-opacity-60 pb-4">
              {order?.notes}
            </div>
            {admin && (
              <>
                <div className="flex items-start text-base font-semibold text-[#FBFAFC]">
                  Contact information
                </div>
                <div className="flex flex-col items-start text-[#FBFAFC] text-opacity-60 pb-4">
                  <div>
                    {order?.contact?.firstName + " " + order?.contact?.lastName}
                    (
                    {order?.contact?.subscriptionStatus
                      ? "Subscribed to "
                      : "Not Subscribed"}
                    {subscriptionPlanString})
                  </div>
                  <div>{order?.contact?.emailId}</div>
                  <div>{order?.contact?.phoneNumber}</div>
                  <div>Inprogress: {inprogressOrdersCount}</div>
                  <div>Completed: {completedOrdersCount}</div>
                </div>
                <div className="flex items-start text-base font-semibold text-[#FBFAFC]">
                  Size
                </div>
                <div className="flex flex-col items-start text-[#FBFAFC] text-opacity-60 pb-4">
                  {order?.size}
                </div>
                <div className="flex items-start text-base font-semibold text-[#FBFAFC]">
                  Address To Ship
                </div>
                <div className="flex flex-col items-start text-[#FBFAFC] text-opacity-60 pb-4">
                  {order?.addressToShip ? (
                    <>
                      <div>{order?.addressToShip.line1}</div>
                      <div>{order?.addressToShip.line2}</div>
                      <div>
                        {order?.addressToShip.city}-{order?.addressToShip.state}{" "}
                        : {order?.addressToShip.zipCode}
                      </div>
                      <div>{order?.addressToShip.country}</div>
                    </>
                  ) : (
                    "No address to ship"
                  )}
                </div>
              </>
            )}
            {admin && (
              <div
                tabIndex={0}
                className="collapse collapse-arrow  bg-[#1B1C2D] rounded-box mb-2"
              >
                <input type="checkbox" className="peer" />
                <div className="collapse-title text-xl font-medium text-white flex flex-start">
                  Review
                </div>
                <div className="collapse-content ">
                  {order?.status === "pending" ? (
                    <div>
                      {admin ? (
                        <div>
                          {/* Render the "Update" section */}
                          <div className="flex items-start text-[#FBFAFC] text-opacity-60">
                            Price
                          </div>
                          <input
                            type="text"
                            placeholder="Enter Price"
                            className="input w-full max-w-xs"
                            value={price}
                            onChange={
                              
                              
                              (e) => setPrice(e.target.value)}
                          />
                          <button
                            className="secondary-button w-full md:w-64 my-4"
                            onClick={(event) => {
                              if (!price || parseInt(price) < 1) {
                                toast.error("Please enter a valid amount", {
                                  toastId: "toast-message",
                                });
                              } else {
                                handleReview(event).then(() => {
                                  toast.success(
                                    "Update Successful. Please wait",
                                    {
                                      toastId: "toast-message",
                                    }
                                  );
                                });
                              }
                            }}
                          >
                            Update
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    // Conditionally render the "New Price Set" section only when the order status is "reviewed"
                    order?.status === "reviewed" && (
                      <div>
                        <input
                          type="text"
                          placeholder="Enter Updated Price"
                          className="input w-full max-w-xs"
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                        />
                        <button
                          className="secondary-button w-full md:w-64 my-4"
                          onClick={(event) => {
                            // Display a confirmation alert
                            const isConfirmed = window.confirm(
                              "Are you sure you want to update the price?"
                            );

                            if (isConfirmed) {
                              if (!price || parseInt(price) < 1) {
                                toast.error("Please enter a valid amount", {
                                  toastId: "toast-message",
                                });
                              } else {
                                handleReview_update_price(event).then(() => {
                                  toast.success(
                                    "Update Successful. Please wait",
                                    {
                                      toastId: "toast-message",
                                    }
                                  );
                                  setPrice("");
                                });
                              }
                            } else {
                              // User clicked "Cancel" in the confirmation dialog, do nothing
                            }
                          }}
                        >
                          New Price Set
                        </button>
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
            {!admin && (
              <div
                tabIndex={0}
                className="collapse collapse-arrow  bg-[#1B1C2D] rounded-box mb-2"
              >
                <input type="checkbox" className="peer" />
                <div className="collapse-title text-xl font-medium text-white flex flex-start">
                  Review
                </div>
                <div className="collapse-content ">
                  {order?.status === "pending" ? (
                    <span className="text-white">You will be quoted soon.</span>
                  ) : (
                    <span className="text-white">Reviewed</span>
                  )}
                </div>
              </div>
            )}
            {/* Payment Section */}
            <div
  tabIndex={0}
  className="collapse collapse-arrow bg-[#1B1C2D] rounded-box mb-2"
>
  <input type="checkbox" className="peer" />
  <div className="collapse-title text-xl font-medium text-white flex flex-start">
    Payment
  </div>
  <div className="collapse-content">
    {order?.status === 'completed' ? (
      // Show remaining payment for completed orders
      <span className="text-white">
        <p className="text-left">
          Remaining Balance Due:{" "}
          <span className="font-bold mr-1">
            ${((order.price / 2) * 1.045).toFixed(2)}
          </span>
          (50% of total)
        </p>
        <br />
        {admin ? (
          <button className="secondary-button w-full md:w-64 my-4">
            Send Payment Reminder
          </button>
        ) : (
          <>
            <Payment 
              order={order} 
              id={clientSecret} 
              amount={(order.price / 2) * 1.045} // Remaining half
              isSecondPayment={true} // Add this prop to handle final payment
            />
            {/* Display the message after payment is made */}
            {order.paymentStatus === 'paid' && (
              <p className="text-white mt-4">Will send file soon</p>
            )}
          </>
        )}
      </span>
    ) : STATUS_CODES[order?.status] < 2 ? (
      // Initial deposit for pending/reviewed orders
      <span className="text-white">
        <p className="text-left">
          Deposit{" "}
          <span className="font-bold mr-1">
            ${((order.price / 2) * 1.045).toFixed(2)}
            <span className="text-sm "> (incl. 4.5% tax) </span>
          </span>
          to get started (50% upfront)
        </p>
    
        <br />
        {admin ? (
          <button className="secondary-button w-full md:w-64 my-4">
            Send Notification
          </button>
        ) : (
          <Payment 
            order={order} 
            id={clientSecret} 
            amount={(order.price / 2) * 1.045} // Initial half
          />
        )}
      </span>
    ) : (
      // Payment status messages
      <p className="text-white font-bold">
        {order?.status === 'paid' 
          ? "Deposit Paid - Project In Progress" 
          : "Payment Complete"}
      </p>
    )}
  </div>
</div>
            <div className="bg-[#1B1C2D] rounded-box mb-2 p-4">
  <div className="text-xl font-medium text-white flex flex-start">
    Chat
  </div>

  <div className="text-sm flex flex-start items-center ml-2">
    {isTyping && typingRole && (
      <div className="typing-indicator">
        {typingRole === "admin" ? "(Admin is typing)" : "(User is typing)"}
      </div>
    )}
  </div>

  <div className="max-h-[250px] overflow-y-scroll p-2 rounded-lg" ref={chatContainerRef} onScroll={handleScroll}>
    {order?.comments?.length > 0 ? (
      order.comments.map((comment) => (
        <div
          className={comment.isAdmin ? "flex justify-start" : "flex justify-end"}
          key={comment.id}
        >
          {comment.isAdmin ? (
            <div className="speech-bubble-left flex gap-3 justify-start items-center text-[#FBFAFC] text-opacity-60 pb-4">
              <div className="w-[30px] bg-black rounded-full ring ring-primary ring-offset-black ring-offset-1">
                <img alt="plc" className="rounded-full w-[30px]" src={logo} />
              </div>
              {comment?.message.startsWith("https:") ? (
                <div className="relative flex items-center">
                  <a href={comment.message} target="_blank" rel="noopener noreferrer">
                    <img className="object-contain w-[150px] h-[150px]" src={comment.message} alt="loading" />
                    <div className="absolute top-0 right-0 bg-black bg-opacity-75 flex items-center justify-center h-full w-full">
                      <FaExternalLinkAlt size={20} color="#fff" />
                    </div>
                  </a>
                </div>
              ) : (
                <div>{comment.message}</div>
              )}
            </div>
          ) : (
            <div className="speech-bubble-right flex gap-3 justify-end items-center text-[#FBFAFC] text-opacity-60">
              {comment?.message.startsWith("https:") ? (
                <div className="relative flex items-center">
                  <a href={comment.message} target="_blank" rel="noopener noreferrer">
                    <img className="object-contain w-[150px] h-[150px]" src={comment.message} alt="loading" />
                    <div className="absolute top-0 right-0 bg-black bg-opacity-75 flex items-center justify-center h-full w-full">
                      <FaExternalLinkAlt size={20} color="#fff" />
                    </div>
                  </a>
                </div>
              ) : (
                <div>{comment.message}</div>
              )}
              <div className="w-[30px] h-[30px] flex items-center bg-black rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                <BiUser className="rounded-full text-xl w-[30px]" />
              </div>
            </div>
          )}
        </div>
      ))
    ) : (
      <div className="text-center text-gray-400 py-2">No messages yet.</div>
    )}

    <div ref={dummyRef} />
  </div>

  {/* Chat Input Section */}
  <div className="speech-bubble my-3 flex items-center">
    <form
      className="w-full"
      onSubmit={(e) => {
        e.preventDefault();
        if (comment && comment.length > 0) {
          handleComment(e);
        }
      }}
    >
      <input
        type="text"
        ref={inputRef}
        placeholder="Enter Comment"
        className="input w-full focus:border-0 text-white bg-transparent"
        onChange={(e) => {
          setComment(e.target.value);
          handleStartTyping();
          handleStopTyping();
        }}
        value={comment}
      />
    </form>
    <label className="flex h-full text-xl text-white justify-center items-center cursor-pointer pr-3" htmlFor="dropzone-file">
      <BiImageAdd />
      <input className="hidden" id="dropzone-file" type="file" multiple onChange={handleFileUpload} accept="image/*" />
    </label>
    <button
      onClick={(e) => {
        e.preventDefault();
        if (comment && comment.length > 0) {
          handleComment(e);
        }
      }}
      className="h-full text-xl justify-center items-center text-white pr-3"
    >
      <AiOutlineSend />
    </button>
  </div>
</div>


            {admin && (
              <div
              
                tabIndex={0}
                className="collapse collapse-arrow  bg-[#1B1C2D] rounded-box mb-2"
              >
                <input type="checkbox" className="peer" />
                <div className="collapse-title text-xl font-medium text-white flex flex-start">
                  Status Updates
                </div>
                <div className="collapse-content">
                  <button
                    className="secondary-button w-full md:w-64 my-4 mx-auto"
                    onClick={() => {
                      updateStatus("inprogress", order._id);

                      toast.success("Marked as in progress");
                    }}
                  >
                    Mark in progress
                  </button>
                  <button
                    className="secondary-button w-full md:w-64  my-4 mx-auto"
                    onClick={() => {
                      updateStatus("completed", order._id).then(() => {
                        setOrders((prevOrders) => {
                          const updatedOrders = prevOrders.filter(
                            (o) => o._id !== order._id
                          );
                          return updatedOrders;
                        });
                        toast.success("Marked as completed");
                      });
                    }}
                  >
                    Mark as completed
                  </button>
                  <button
                    className="secondary-button w-full md:w-64  my-4 mx-auto"
                    onClick={() => cancelOrder(order._id)}
                  >
                    Cancel Order
                  </button>
                </div>
              </div>
            )}
            {admin && order?.addressToShip != "undefined" && (
              <div
                tabIndex={0}
                className="collapse collapse-arrow  bg-[#1B1C2D] rounded-box mb-2"
              >
                <input type="checkbox" className="peer" />
                <div className="collapse-title text-xl font-medium text-white flex flex-start">
                  Shipping
                </div>
                <div className="collapse-content pt-2">
                  {" "}
                  <button
                    className="secondary-button w-full md:w-64 my-4 mx-auto"
                    onClick={() => {
                      updateStatus("cancelled");
                    }}
                  >
                    Mark as shipped
                  </button>
                </div>
              </div>
            )}
            <div />
          </div>
        </ul>
      </div>
    </div>
  );
};

export default OrdersList;